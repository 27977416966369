$appTheme: #0e699c;
$secondTheme: #1cadd7;
$primebtnbg: #1cadd7;
$primebtnhover: #20b9e0;
$sectionTitle: #01689e;
:root {
    --appTheme: #e23744;
    --greyTheme: #d3d1d1;
    --cardFixedWidth: 400px;
}
@mixin primary-btn($bg: $primebtnbg, $bdr: $primebtnbg, $color: #fff) {
    background: $bg;
    border: 1px solid $bdr;
    min-width: 120px;
    border-radius: 0.25rem;
    padding: 0.25rem 20px;
    max-height: 2.5rem;
    color: $color;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.85rem;
    line-height: normal;

    &.btn-xl {
        height: 2.5rem;
    }

    &.smallBtn {
        padding: 0.25rem 30px;
    }

    &:hover,
    &:active {
        text-decoration: none;
        color: #fff;
        background: $primebtnhover !important;
        border-color: $primebtnhover !important;
    }

    span {
        margin: 0 0.25rem;
    }
}
@mixin boxShadowa($yaxis: 1.5rem, $xaxis: 1rem) {
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: $yaxis $xaxis;
}
