@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --color-primary: rgba(200, 15, 46, 1);
  --color-grade-50: rgba(200, 15, 46, 0.5);

  --color-success: #1a932e;
  --color-red: #e23744;
}
@layer base {
  body {
    /* font-family: "DM Sans", sans-serif !important; */
    @apply font-inter;
  }
  input::placeholder {
    font-size: 12px;
  }
  em {
    @apply not-italic;
  }
  input:focus {
    outline: none;
  }
}
@layer components {
  .primary-hisa {
    @apply bg-blue shadow rounded py-2 text-white text-sm font-medium capitalize flex items-center;
    &:hover {
      @apply bg-blue shadow;
    }
    &.show {
      @apply bg-blue;
    }
  }
  .transBg {
    @apply bg-appbg -m-4 flex flex-col;
    min-height: calc(100vh - 80px);
  }
  .dashboardHeader {
    @apply flex flex-wrap;
  }
  .dashboardHeaderFluid {
    @apply splittedBg flex;
    li {
      @apply border-r border-black-5 flex w-1/4 justify-center;
      &:last-child {
        @apply border-none;
      }
      /* &:first-child {
                > * {
                    @apply !pl-[10%];
                }
            } */
      > * {
        @apply px-[10%] 2xl:px-[15%] text-center;
      }
    }
  }
  .splittedBg {
    @apply bg-white rounded-xl !p-6;
  }
  .smallCard {
    @apply bg-white rounded-md !p-2 !py-1 flex items-center mb-2;
  }
  .cardTitle {
    @apply text-xl font-medium text-black-10;
  }
  .hisaTableWrapper {
    @apply overflow-x-auto !p-0;
  }
  .hisaTable {
    @apply min-w-full;
    thead {
      @apply border-y text-black-7 border-black-4 text-xs max-md:bg-blue max-md:text-white;
    }
    th {
      @apply !py-4 !px-2 font-medium;
    }
    td {
      @apply !py-3 !px-2 font-medium text-black-12 text-xs md:text-sm;
    }
    tbody {
      @apply border-b border-black-4;
    }
  }

  .hisaTabs {
    @apply flex md:inline-flex !p-0 rounded-md;
    .nav-link {
      @apply px-2 md:!px-8 text-black-8 text-sm font-medium max-md:flex-auto max-md:text-center;
      /* &.active {
                @apply bg-blue text-white;
            } */
    }
    .nav-link.active {
      @apply bg-blue text-white;
    }
    &:not(.verticalTab) .nav-link {
      @apply flex items-center justify-center max-md:text-xs;
      /* &:first-child {
                &.active {
                    @apply rounded-l;
                }
            }
            &:last-child {
                &.active {
                    @apply rounded-r;
                }
            } */
    }
    &:not(.verticalTab) .nav-item .nav-link {
      &:first-child.active {
        @apply rounded-l;
      }
      &:last-child.active {
        @apply rounded-r;
      }
    }
    &.verticalTab {
      @apply !mr-2;
    }
    &.verticalTab a {
      @apply !p-4;
    }
    &.settingsTab a {
      @apply !px-4 !py-3;
    }
    &.verticalTab .nav-item {
      @apply w-full;
    }
    &.verticalTab .nav-link {
      &:first-child {
        &.active {
          @apply rounded-t;
        }
      }
      &:last-child {
        &.active {
          @apply rounded-b;
        }
      }
    }
  }
  .dashboardTabs {
    @apply !px-0;
    .nav-item {
      @apply text-sm w-full;
    }
    .nav-link {
      @apply border-b-2 border-bdr border-solid flex-1 flex justify-center text-titleText font-semibold;
    }
    .nav-item .active {
      @apply border-b-2 border-blue text-blue;
    }
  }
  .pageTitle {
    @apply bg-blue50 md:bg-blue md:text-white text-black-10 text-base font-semibold rounded !px-4 !py-3 !mb-4;
  }
  .tailFormWrap {
    .fieldWrapper {
      @apply md:w-1/2 !px-4 !pb-4;
    }
    .MuiFormControl-root {
      @apply w-full;
    }
    .MuiInputBase-root::before {
      @apply border-black-5;
    }
    .MuiFormLabel-root {
      @apply text-sm text-black-13/60;
    }
    .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
      -webkit-transform: translate(0, 17px) scale(1);
      -moz-transform: translate(0, 17px) scale(1);
      -ms-transform: translate(0, 17px) scale(1);
      transform: translate(0, 17px) scale(1);
    }
    .css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus {
      background-color: transparent;
    }
    /* .css-cv41kj-MuiInputBase-root-MuiInput-root::after {
            @apply border-blue;
        } */
    /* .css-1o0voyd-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
            @apply text-blue;
        } */
    .MuiInput-root:after {
      @apply border-b-black-13/60;
    }
  }
  .tailRadio {
    .MuiFormControlLabel-label {
      @apply text-blue font-bold text-sm;
    }
  }
  .checkboxCard {
    @apply grid grid-cols-4 gap-4;
    .MuiFormControlLabel-root {
      /* @apply bg-grade-100/15 rounded !p-2 !m-0; */
    }
    .MuiFormControlLabel-label {
      @apply text-sm;
    }
    .Mui-checked + .MuiFormControlLabel-label {
      @apply text-blue font-semibold;
    }
  }
  .hisaModal {
    overflow-y: auto;
    .modalBox {
      @apply flex justify-center mx-auto my-4 items-center outline-none min-h-screen;
    }
    .modalContent {
      @apply bg-white outline-none rounded-xl w-full;
    }
    .modalHeader {
      @apply bg-black-3 py-4 px-6 text-blue font-semibold justify-between flex items-center rounded-t-xl;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @apply text-blue;
      }
    }
  }
  .Mui-disabled {
    /* @apply bg-black-5; */
  }
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    transform: translate(14px, 50%) scale(1);
  }
  .chartWrap {
    > div {
      @apply w-full;
    }
  }
  .formUplod {
    @apply relative;
    input {
      @apply opacity-0 cursor-pointer z-10;
    }
    fieldset {
      @apply !border-0;
    }
    .uploadLabel {
      @apply absolute rounded-md border border-blue left-0 top-0 flex justify-center w-full h-full items-center text-blue text-sm;
    }
  }
  .pdfIcon {
    svg {
      @apply h-20 w-20 text-red-300;
    }
  }
  .activeTheme {
    @apply flex items-center justify-center text-white outline outline-offset-2 outline-black-6;
  }
  .shadowGrid {
    @apply shadow-[0_0_5px_2px_rgba(0,0,0,0.05)];
  }
  .activeGrid {
    @apply bg-blue text-white;
    span,
    h1 {
      @apply text-white;
    }
  }
  .spinner-load:before {
    @apply content-[""] fixed w-screen h-screen top-0 left-0 bg-black-13/60 z-40;
  }
  .spinner-load:after {
    @apply content-[""] animate-spin size-8 border-white border-4 border-t-blue rounded-full z-50 fixed top-1/2 left-1/2;
  }
  .filterActive {
    @apply bg-blue text-white;
  }
  .userZoneWrap {
    > li {
      /* @apply bg-blue50 px-4 rounded; */
    }
    .checkbox-icon {
      @apply mr-2;
    }
    .tree-node {
      @apply bg-blue50 p-4 rounded mb-2 flex items-center text-white;
    }
  }
  .receive {
    @apply text-left mr-auto;
  }
  .sendMsg {
    @apply text-right ml-auto;
  }
  .helpMsg {
    @apply bg-white rounded-md p-2 inline-block mb-2 text-[13px] max-w-[75%];
  }
}
@layer utilities {
  .font-sans {
    font-family: "DM Sans", sans-serif;
  }
}
.tree-node--expanded {
  .arrow {
    transform: rotate(90deg);
  }
}
.MuiCheckbox-root.Mui-checked,
.MuiRadio-root.Mui-checked {
  color: var(--color-primary);
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-p0rm37 {
  transform: translate(14px, 50%) scale(1);
  font-size: 0.85rem;
}
.sidenav {
  max-height: calc(100vh - 64px);
}
.MuiLoadingButton-loading {
  background-color: var(--color-primary) !important;
}
.MuiLoadingButton-loadingIndicatorCenter {
  color: #fff;
}
.MuiTextField-root {
  margin-left: 0;
}
.MuiCheckbox-root {
  padding: 0 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.css-1o0voyd-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.css-fj7reh.Mui-focused {
  color: rgba(0, 0, 0, 0.6);
}
.DELoginWrap .css-1o0voyd-MuiFormLabel-root-MuiInputLabel-root,
.DELoginWrap .css-fj7reh {
  font-size: 1.25rem;
  color: inherit;
}
.css-cv41kj-MuiInputBase-root-MuiInput-root::after,
.css-64kdi0.Mui-focused::after {
  border-bottom-color: rgba(0, 0, 0, 0.6);
}
.MuiBadge-badge {
  padding: 0;
  background-color: var(--color-primary);
}
.carousel-indicators {
  & button {
    width: 12px !important;
    height: 12px !important;
    border-radius: 50%;
    border: none !important;
  }
  & button.active {
    width: 30px !important;
    border-radius: 30px;
  }
}
.css-11pbyhm-MuiSvgIcon-root {
  width: 1.25rem;
  height: 1.25rem;
}
.css-zza0ns-MuiBadge-badge {
  min-width: 1rem;
  height: 1rem;
}
.MuiSwitch-switchBase.Mui-checked {
  color: var(--color-primary);
}
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: var(--color-primary);
}
.MuiPickersLayout-actionBar {
  display: none;
}
.warning-color {
  color: #e23744;
}
.success-color {
  color: #1a932e;
}
.css-1ow4h4h-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0;
}
.rce-input-textarea {
  height: 80px !important;
  border-radius: 0.375rem !important;
  padding: 5px !important;
}
@media screen and (max-width: 767px) {
  .MuiBadge-badge {
    /* padding: 0; */
    background-color: #fff;
    color: var(--color-primary);
  }
  .css-11pbyhm-MuiSvgIcon-root {
    color: #fff;
  }
}
