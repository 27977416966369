// Implementing the general styling
@import url("../../assets/css/_aladdinVars.scss");
@mixin boxShadow($yaxis: 1.5rem, $xaxis: 1rem) {
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: $yaxis $xaxis;
}
@mixin primaryBtn() {
    background: var(
        --gradient,
        linear-gradient(
            90deg,
            rgba(255, 83, 96, 1) 0%,
            rgba(198, 34, 47, 1) 47.899964451789856%,
            rgba(144, 18, 28, 1) 100%
        )
    );
    border-radius: 8px;
    padding: 0.75rem 0;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1),
        0px 1px 10px 0px rgba(0, 0, 0, 0.1);
    color: #fff;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: initial;
}

ul {
    list-style: none;
    margin: 0;
}

.btn {
    cursor: pointer;

    &:focus {
        box-shadow: none;
    }
}

input:focus {
    box-shadow: none;
}

a,
a:hover {
    text-decoration: none;
}

/* Scroll bar */
::-webkit-scrollbar {
    width: 3px;
    // height: 3px;
    border-radius: 50px;
}
.shadowBg {
    @include boxShadow;
}
.primaryBtn,
.MuiButtonBase-root.primaryBtn {
    @include primaryBtn;
    line-height: normal;
    text-transform: initial;
}
.formError {
    color: #d32f2f;
    font-size: 0.75rem;
}
