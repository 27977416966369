@import url("../pages/scss/general.scss");
// body {
//     * {
//         font-family: "DM Sans", sans-serif;
//         font-optical-sizing: auto;
//         font-weight: normal;
//         font-style: normal;
//         line-height: normal;
//     }
// }
input:-webkit-autofill {
    background-color: transparent !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}
